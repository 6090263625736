@import "https://use.typekit.net/wiz1geb.css";

$primary-color: #19101d;
$accent-color: #cb563b;
$gradient: linear-gradient(#19101d, #1a485c);
$font-color: whitesmoke;
$font-family: nobel, sans-serif;

$x-large: 6rem;
$large: 4.4rem;
$medium: 3.5rem;
$small-m: 3rem;
$small: 2.3rem;
$x-small: 2rem;
$xx-small: 1.5rem;
$xxx-small: 1rem;
$xxxx-small: 0.5rem;