@import "commonStyles";

* {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    color: $font-color;
    text-decoration: none;
}


html {
    background: $gradient;
    min-height: 100%;
}

::-webkit-scrollbar {
    display: none;
}

.home-container {
    max-width: 960px;
    margin: auto;
    overflow: hidden;
    padding: 0 3rem;
}

.home-showcase {
    background: $primary-color;
    color: $font-color;
    height: 100vh;
    background-image: url("../icons/home/Background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .showcase-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    h1 {
        font-weight: 300;
        font-style: normal;
        font-size: $x-large;
    }
}

@media (max-width: 1090px) {
    .home-showcase {
        .showcase-inner {
            height: 90%;
        }

        h1 {
            text-align: center;
            font-size: $large;
        }
    }

}