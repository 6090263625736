@import "commonStyles";

.question-list {
    margin-left: 200px;
    margin-right: 200px;
    font-weight: 200;
    text-align: left;
    line-height: 1.5;
    padding-bottom: 30px;
    font-weight: 300;
    font-size: $x-small;
}

.report-container {
    iframe {
        border: "none";
        display: "block";
        margin: "auto";
        overflow: "hidden";
        transform: "scale(0.9)";
    }
}

@media (max-width: 1090px) {

    .question-list {
        margin-left: 20px;
        margin-right: 20px;
    }
}