@import "commonStyles";

.canvasContainer {
  text-align: center;
  margin: 20px auto;
  width: 50%;
  filter: drop-shadow(5px 5px 5px rgba(25, 16, 29, 0.8));

  canvas {
    border-radius: 5%;
  }
}

.characterContainer {
  margin: auto;
  width: 50%;

  filter: drop-shadow(5px 5px 5px rgba(25, 16, 29, 0.8));

  table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
  }

  .table-header {
    background-color: $accent-color;

    th {
      font-size: $xx-small;
      color: $font-color;
    }
  }

  .table-content {
    border: 1px solid $accent-color;
    background-color: $primary-color;
    td {
      padding: 10px;
      font-size: $xxx-small;
      color: $font-color;
    }
  }
}

.rarity-legendary {
  font-weight: bold;
  color: $font-color;
}

@keyframes shake {
  0% { transform: translateX(0) scale(1); }
  20% { transform: translateX(-2px) scale(1.1); }
  40% { transform: translateX(2px) scale(1.2); }
  60% { transform: translateX(-2px) scale(1.1); }
  80% { transform: translateX(2px) scale(1.2); }
  100% { transform: translateX(0) scale(1); }
}

.shake-text {
  display: inline-block;
  animation: shake 0.5s infinite alternate ease-in-out;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(255, 255, 0, 0.8); /* Glow effect */
}



.btn-container {
  text-align: center;
  margin: 0 auto;

  Button {
    height: $medium;
    padding: 10px 20px;
    margin: 15px;
    border-radius: 10px;
    color: black;
    font-size: $xx-small;
    font-family: $font-family;
    background-color: $gradient;
  }

  Button:hover {
    background-color: $accent-color;
    color: $font-color;
    transition: background-color 0.2s;
  }
}

@media (max-width: 1090px) {

  .canvasContainer {
    width: 75%;
  }

  .characterContainer {
    width: 75%;

    .table-header {
      th {
        font-size: $xxx-small;
      }
    }
  }
  
  canvas {
    height: 75%;
    width: 75%;
  }
}